import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
      <div style={{ marginTop: "200px" }}> </div>
      <h1>H Σελίδα δεν βρέθηκε</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
